import React from 'react';
import { Paper, Grid } from '@mui/material';
import { useLanguageSettings } from '../contexts/LanguageSettingsContext';

export function AssignmentInstructions({ assignment }) {
  const { languageSettings } = useLanguageSettings();
  const translatedTitle = assignment.translations?.[languageSettings.language]?.title || assignment.title;
  const translatedInstructions = assignment.translations?.[languageSettings.language]?.instructions || assignment.instructions;

  return (
    <Paper sx={{ mb: 5, padding: 2, backgroundColor: '#f0f6ff' }}>
      <Grid container spacing={1} rowSpacing={2}>
        <Grid item container>
          <Grid item xs={12}>
            <strong>{translatedTitle}</strong>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={12} sx={{ whiteSpace: 'pre-line', fontStyle: 'italic' }}>
            {translatedInstructions}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
