import { Box, Tooltip, Typography } from '@mui/material';

import { useMemo } from 'react';
import { HelpOutline as HelpIcon } from '@mui/icons-material';
import { TextEditor } from '../../../../../components/TextEditor';
import { useStepData, useUpdateStepData } from '../../../../../contexts/StepsDataContext';
import { useCurrentField, useUpdateCurrentField } from '../../../../../contexts/GwenFieldContext';

export function OutlineItem({ disabled, question, userAssignmentId, component, active, description }) {
  const value = useStepData(component.id);
  const updateOutlineStepValue = useUpdateStepData();
  const currentField = useCurrentField();
  const setCurrentField = useUpdateCurrentField();

  const styles = useMemo(
    () => ({
      height: '75px',
      border: '1px solid transparent',
      ...(currentField === component.id
        ? {
            border: '1px solid rgb(63, 80, 181)',
            boxShadow: '0 0 8px rgba(63, 80, 181, 0.5)',
          }
        : {}),
    }),
    [currentField]
  );

  return (
    <Box sx={{ paddingBottom: 2, '&:last-child': { paddingBottom: 0 } }}>
      <Typography sx={{ marginY: 1 }}>
        {question}
        <Tooltip title={description}>
          <HelpIcon fontSize="small" sx={{ marginLeft: 1 }} />
        </Tooltip>
      </Typography>
      <TextEditor
        containerStyles={styles}
        disabled={disabled}
        initialValue={value}
        onFocus={() => setCurrentField(component.id)}
        onTextChange={(text) => updateOutlineStepValue(component.id, { value: text })}
        userAssignmentId={userAssignmentId}
        step={component.id}
        active={active}
      />
    </Box>
  );
}
