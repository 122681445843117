import { useCallback } from 'react';
import http, { aiRequestTimeout } from '../../utils/http';
import { useLanguageSettings } from '../../contexts/LanguageSettingsContext';

// TODO: Rewrite to use user id and get grade at the backend?
const getWhatDoYouThink = async (text, assignmentId, grade, outlineText, outlineStructure, languageSettings) => {
  try {
    const { data } = await http.post(
      '/api/ai/what-do-you-think',
      {
        text,
        assignmentId,
        grade,
        outlineText,
        outlineStructure,
        clientTime: performance.timeOrigin + performance.now(),
        language: languageSettings.language,
        simpleLanguage: languageSettings.simpleLanguage,
      },
      aiRequestTimeout
    );
    return data;
  } catch (error) {
    return {
      promptResult: error.response.data?.error || `Gwen's help is not available at the moment.`,
      prompt: '',
    };
  }
};

export const useWhatDoYouThink = ({
  assignmentId,
  onLoading,
  setPromptResult,
  setPrompt,
  text,
  grade,
  outlineText,
  outlineStructure,
}) => {
  const { languageSettings } = useLanguageSettings();

  return useCallback(async () => {
    onLoading(true);

    const data = await getWhatDoYouThink(text, assignmentId, grade, outlineText, outlineStructure, languageSettings);
    setPromptResult(data.promptResult);
    setPrompt(data.prompt);
    onLoading(false);
  }, [text, languageSettings]);
};
