import { Box, Stack } from '@mui/material';
import { Bubble } from './Bubble';
import { TextToSpeech } from './TextToSpeech';
import { useLanguageSettings } from '../../contexts/LanguageSettingsContext';

export function PenGwen({ children, promptResult, textToSpeech = false, ...props }) {
  const { languageSettings } = useLanguageSettings();
  return (
    <Box {...props}>
      {promptResult && (
        <Box sx={{ textAlign: 'center', marginBottom: 1, marginTop: 2 }}>
          <Bubble>
            {promptResult}
            {/* <TextToSpeech text={promptResult} language={language} /> */}
          </Bubble>
        </Box>
      )}
      <Box sx={{ paddingY: 2, textAlign: 'center', position: 'relative' }}>
        <picture>
          <source srcSet="/gwen.png, /gwen@2x.png 2x" />
          <img alt="Gwen - a helping tutor" />
        </picture>
      </Box>

      <Stack direction="column" alignItems="center" gap={1}>
        {/* <LanguageSelector textToSpeech={textToSpeech} promptResult={promptResult} /> */}
        {children}
      </Stack>
    </Box>
  );
}
