import { useState, useRef, useEffect } from 'react';
import { Container, Grid, Box, Button, Paper, TextField, CircularProgress } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { ReactSortable } from 'react-sortablejs';
import { enqueueSnackbar } from 'notistack';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumb } from '../../components/layout/Breadcrumb';
import { StyledButtonBlock } from './components/StyledButtonBlock';
import { SortableButton } from './components/SortableButton';
import { useTemplateMutation } from './api/useTemplateMutation';
import { Form } from './components/Form';
import { ROUTES } from '../../routes';
import { useTemplate } from './api/useTemplate';
import { LoadingError } from '../../components/LoadingError';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import {
  addComponent,
  addSection,
  buildTemplateRequestData,
  deleteComponent,
  deleteSection,
  duplicateComponent,
  duplicateSection,
  editComponent,
  getComponentData,
  loadDataFromTemplate,
  updateSection,
} from './utils';

const actionButtonStyle = {
  borderStyle: 'dashed',
  '&:hover': {
    borderStyle: 'dashed',
  },
};

const stickyStyle = {
  position: 'sticky',
  top: 100, // drawer height
};

export function AddTemplate() {
  const idCounter = useRef(0);

  // preexisting template - duplicated or edited
  const templateToCopyId = useLocation().state?.templateId;
  const { data: templateToCopy, isLoading, isError, error } = useTemplate(templateToCopyId);
  const [templateName, setTemplateName] = useState('New Template');
  const [sections, setSections] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [newComponentToSelectId, setNewComponentToSelectId] = useState('');
  const [newSectionToSelectId, setNewSectionToSelectId] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();
  const mutation = useTemplateMutation({
    onSuccess: () => {
      navigate(ROUTES.TEMPLATES_INDEX);
      setIsSaving(false);
      enqueueSnackbar('Template has been added', {
        autoHideDuration: 3000,
        variant: 'success',
      });
    },
  });

  const handleCreateNewTemplate = (name, templateSections) => {
    setIsSaving(true);
    const values = {
      name,
      sections: buildTemplateRequestData(templateSections),
    };

    mutation.mutate({ data: values });
  };

  const selectComponent = (e) => {
    const componentId = e.target.value;
    setSelectedComponent(getComponentData(componentId, sections));
  };

  useEffect(() => {
    selectComponent({ target: { value: newComponentToSelectId } });
  }, [newComponentToSelectId]);

  useEffect(() => {
    selectComponent({ target: { value: newSectionToSelectId } });
  }, [newSectionToSelectId]);

  const generateNewSectionId = () => {
    idCounter.current += 1;
    return `section-${idCounter.current}`;
  };

  const generateNewComponentId = () => {
    idCounter.current += 1;
    return `component-${idCounter.current}`;
  };

  const handleAddComponent = (sectionId) => {
    const newComponentId = generateNewComponentId();
    setSections(addComponent(sectionId, newComponentId, sections));

    setNewComponentToSelectId(newComponentId);
  };

  const handleDuplicateComponent = (sectionId, componentId) => {
    const newComponentId = generateNewComponentId();
    setSections(duplicateComponent(sectionId, componentId, newComponentId, sections));

    setNewComponentToSelectId(newComponentId);
  };

  const handleDeleteComponent = (sectionId, componentId) => {
    setSections(deleteComponent(sectionId, componentId, sections));
  };

  const handleAddSection = () => {
    const newSectionId = generateNewSectionId();
    setSections(addSection(newSectionId, sections));

    setNewSectionToSelectId(newSectionId);
  };

  const handleDuplicateSection = (sectionId) => {
    const sectionToDuplicate = sections.find((section) => section.id === sectionId);
    const newComponentIds = Array.from({ length: sectionToDuplicate.components.length }, () =>
      generateNewComponentId()
    );
    const newSectionId = generateNewSectionId();
    setSections(duplicateSection(sectionId, newSectionId, newComponentIds, sections));

    setNewSectionToSelectId(newSectionId);
  };

  const handleDeleteSection = (sectionId) => {
    setSections(deleteSection(sectionId, sections));
  };

  // for updating react sortable list updates
  const handleUpdateSection = (sectionId, newComponents) => {
    setSections(updateSection(sectionId, newComponents, sections));
  };

  // for editing sections and components data
  const handleEditComponent = (id, newData) => {
    setSections(editComponent(id, newData, sections));
  };

  if (templateToCopyId && isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <LoadingError error={error} />;
  }

  if (templateToCopy && templateToCopy?.templateComponents.length !== 0 && sections.length === 0) {
    const { templateStructure, name } = loadDataFromTemplate(
      templateToCopy,
      generateNewSectionId,
      generateNewComponentId
    );

    setSections(templateStructure);
    setTemplateName(`${name} (duplicated)`);
  }

  return (
    <Container>
      {/* TODO: Configure Breadcrumb */}
      <Breadcrumb />
      <Grid container gap={4}>
        <Grid container>
          <TextField
            fullWidth
            value={templateName}
            id="templateName"
            label="Template Name"
            name="templateName"
            onChange={(e) => setTemplateName(e.target.value)}
            onFocus={(e) => e.target.select()}
            helperText={'Create meaningful name for this template, something like "1 Paragraph Writing Assignment"'}
            variant="outlined"
          />
        </Grid>

        <Grid container gap={4}>
          <Grid item container direction="column" xs md gap={1}>
            <Grid container direction="column" gap={2}>
              <ReactSortable
                list={sections}
                setList={setSections}
                handle=".drag-handle"
                animation="200"
                dragClass="sortableDrag"
                easing="cubic-bezier(0.1, -0.6, 0.2, 0)"
                swapThreshold={0.7}
              >
                {sections.map((section) => (
                  <Grid item key={section.id}>
                    <Grid item sx={{ paddingY: 1 }}>
                      <SortableButton
                        sortable
                        editMode
                        incomplete={!section.name || section.name === 'SECTION NAME'}
                        value={section.id}
                        selected={selectedComponent?.id === section.id}
                        onClick={selectComponent}
                        onCopy={() => {
                          handleDuplicateSection(section.id);
                        }}
                        onDelete={() => {
                          handleDeleteSection(section.id);
                        }}
                      >
                        {section.name || 'NAME MISSING!'}
                      </SortableButton>
                    </Grid>

                    <ReactSortable
                      list={section.components}
                      setList={(newComponents) => {
                        handleUpdateSection(section.id, newComponents);
                      }}
                      handle=".drag-handle"
                      animation="200"
                      dragClass="sortableDrag"
                      easing="cubic-bezier(0.1, -0.6, 0.2, 0)"
                      swapThreshold={0.7}
                    >
                      {section.components.map((component) => (
                        <Grid item container sx={{ justifyContent: 'end' }} key={component.id}>
                          <Box sx={{ minWidth: 'fit-content', width: '75%', marginY: 0.5 }}>
                            <SortableButton
                              sortable
                              editMode
                              incomplete={
                                !component.shortDescription || !component.name || component.name === 'COMPONENT NAME'
                              }
                              value={component.id}
                              selected={selectedComponent?.id === component.id}
                              onClick={selectComponent}
                              onCopy={() => {
                                handleDuplicateComponent(section.id, component.id);
                              }}
                              onDelete={() => {
                                handleDeleteComponent(section.id, component.id);
                              }}
                            >
                              {component.name || 'NAME MISSING!'}
                            </SortableButton>
                          </Box>
                        </Grid>
                      ))}
                    </ReactSortable>

                    <Grid item container sx={{ justifyContent: 'end' }}>
                      <Box sx={{ minWidth: 'fit-content', width: '75%' }}>
                        <Grid item>
                          <StyledButtonBlock sx={actionButtonStyle} onClick={() => handleAddComponent(section.id)}>
                            + Add Component
                          </StyledButtonBlock>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                ))}
              </ReactSortable>
            </Grid>

            <Grid item>
              <StyledButtonBlock sx={actionButtonStyle} onClick={handleAddSection}>
                + Add Section
              </StyledButtonBlock>
            </Grid>
          </Grid>

          <Grid item container direction="column" spacing={2} xs md>
            <Grid item sx={stickyStyle}>
              {selectedComponent && (
                <Box>
                  <Box>
                    <Paper sx={{ padding: 2 }}>
                      <Form selectedComponent={selectedComponent} handleEditComponent={handleEditComponent} />
                    </Paper>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingY: 2, gap: 2 }}>
        <Button name="cancel" to={ROUTES.TEMPLATES_INDEX} startIcon={<CancelIcon />} component={Link}>
          Cancel
        </Button>
        <Button
          name="save"
          type="submit"
          startIcon={isSaving ? <CircularProgress size={20} color="inherit" /> : <SaveAsIcon />}
          variant="contained"
          component={Link}
          onClick={() => handleCreateNewTemplate(templateName, sections)}
          disabled={isSaving}
        >
          {isSaving ? 'Saving...' : 'Save'}
        </Button>
      </Box>
    </Container>
  );
}
