import { createContext, useContext, useState, useMemo } from 'react';

const LanguageSettingsContext = createContext();

export function LanguageSettingsProvider({ children }) {
  const [languageSettings, setLanguageSettings] = useState({
    language: 'en-US',
    simpleLanguage: false,
  });

  const updateLanguageSettings = (newSettings) => {
    setLanguageSettings((prev) => ({
      ...prev,
      ...newSettings,
    }));
  };

  const value = useMemo(
    () => ({ languageSettings, updateLanguageSettings }),
    [languageSettings]
  );

  return (
    <LanguageSettingsContext.Provider value={value}>
      {children}
    </LanguageSettingsContext.Provider>
  );
}

export function useLanguageSettings() {
  const context = useContext(LanguageSettingsContext);
  if (!context) {
    throw new Error('useLanguageSettings must be used within a LanguageSettingsProvider');
  }
  return context;
}
