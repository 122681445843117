import { Step, StepLabel, Stepper } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomStepLabel = styled(StepLabel)(({ theme, active }) => ({
  '& .MuiStepLabel-label': {
    color: active ? theme.palette.primary.main : theme.palette.text.secondary,
    fontWeight: active ? 600 : 400,
  },
  '& .MuiStepIcon-root.Mui-active': {
    color: theme.palette.primary.main,
  },
  '& .MuiStepIcon-root.Mui-completed': {
    color: theme.palette.success.main,
  }
}));

export default function Stager({ stages, activeStage }) {
  return (
    <Stepper activeStep={activeStage} alternativeLabel>
      {stages.map((name, index) => (
        <Step key={name}>
          <CustomStepLabel active={index === activeStage}>{name}</CustomStepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
