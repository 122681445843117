import { useCallback, useRef } from 'react';
import { getWordCount } from '../../utils/text';
import http, { aiRequestTimeout } from '../../utils/http';
import { useLanguageSettings } from '../../contexts/LanguageSettingsContext';

const getWriteSthForMe = async (text, assignmentId, grade, outlineText, outlineStructure, languageSettings) => {
  try {
    const { data } = await http.post(
      '/api/ai/write-sth-for-me',
      {
        text,
        assignmentId,
        grade,
        outlineText,
        outlineStructure,
        clientTime: performance.timeOrigin + performance.now(),
        language: languageSettings.language,
        simpleLanguage: languageSettings.simpleLanguage,
      },
      aiRequestTimeout
    );
    return data;
  } catch (error) {
    return {
      promptResult: '',
      prompt: '',
    };
  }
};

const wereEnoughTokensWritten = (writtenTextTokens, currentTokens) => currentTokens - writtenTextTokens >= 16;

export const useWriteSthForMe = ({
  assignmentId,
  onLoading,
  setPromptResult,
  setPrompt,
  text,
  grade,
  outlineText,
  outlineStructure,
}) => {
  const textWordCount = getWordCount(text);
  const wordsCountRef = useRef(textWordCount);
  const { languageSettings } = useLanguageSettings();

  return useCallback(
    async (e) => {
      onLoading(true);

      if (!e.altKey && textWordCount > 0 && !wereEnoughTokensWritten(wordsCountRef.current, textWordCount)) {
        setPromptResult("It's your turn! Can you write a few words now?");
        onLoading(false);
        return;
      }

      const data = await getWriteSthForMe(text, assignmentId, grade, outlineText, outlineStructure, languageSettings);
      if (data.promptResult === '') {
        setPromptResult("Unfortunately, I can't write anything for you right now.");
      }

      setPromptResult(data.promptResult);
      setPrompt(data.prompt);
      wordsCountRef.current = textWordCount;
      onLoading(false);
    },
    [text, languageSettings]
  );
};
