import React, { useState, useEffect } from 'react';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { FormControlLabel, IconButton } from '@mui/material';

export function TextToSpeech({ text, language = 'en-US' }) {
  const [isSpeechActive, setIsSpeechActive] = useState(false);
  const [isLanguageSupported, setIsLanguageSupported] = useState(true);
  const { speechSynthesis } = window;

  useEffect(() => {
    const checkLanguageSupport = () => {
      const voices = speechSynthesis.getVoices();
      // console.log('Dostępne głosy:', voices);
      if (voices.length === 0) return;
      const isSupported = voices.some((voice) => voice.lang.startsWith(language.split('-')[0]));
      // console.log('Czy język jest wspierany:', isSupported, 'dla języka:', language);
      setIsLanguageSupported(isSupported);
    };

    checkLanguageSupport(); // INFO: check language support on first render
    speechSynthesis.onvoiceschanged = checkLanguageSupport; // INFO: check language support on voice change
// z jakiegos powodu dwa razy sie to renderuje
    console.log('Stan aktywności mowy:', isSpeechActive);
    console.log('Czy język wspierany:', isLanguageSupported);

    if (isSpeechActive && isLanguageSupported) {
      console.log('Próba utworzenia utterance dla tekstu:', text);
      const utterance = new SpeechSynthesisUtterance(text);
      const voices = speechSynthesis.getVoices();

      const languagePrefix = language.split('-')[0];
      const preferredVoice = voices.find((voice) => languagePrefix === 'en' ? voice.name === 'Samantha' : voice.lang.startsWith(languagePrefix));

      console.log('Wybrany głos:', preferredVoice);
      if (preferredVoice) {
        utterance.voice = preferredVoice;
      }
      utterance.lang = language;
      // utterance.onend = () => {
      //   console.log('Zakończono mówienie');
      //   setIsSpeechActive(false);
      // };

      console.log('Próba wywołania speak');
      speechSynthesis.speak(utterance);
    }

    return () => {
      speechSynthesis.cancel();
      speechSynthesis.onvoiceschanged = null;
    };
  }, [isSpeechActive, text, language, speechSynthesis, isLanguageSupported]);

  const speechProps = {
    fontSize: 'small',
    style: { height: 18, position: 'absolute', left: 1, top: 1, opacity: isLanguageSupported ? 1 : 0.5 }
  };

  return (
    <FormControlLabel
      sx={{ paddingLeft: '7px' }}
      control={
        <IconButton onClick={() => setIsSpeechActive(!isSpeechActive)} size="small" disabled={!isLanguageSupported}>
          {isSpeechActive ? <VolumeUpIcon /> : <VolumeOffIcon />}
        </IconButton>
      }
      label={isSpeechActive ? 'Gwen Speaks' : 'Gwen Silent'}
    />
    // <button
    //   type="button"
    //   onClick={() => setIsSpeechActive(!isSpeechActive)}
    //   disabled={!isLanguageSupported}
    //   style={{cursor: isLanguageSupported ? 'pointer' : 'not-allowed', height: 24, width: 24, position: 'relative' }}
    // >
    //   {isSpeechActive ? <VolumeUpIcon {...speechProps} /> : <VolumeOffIcon {...speechProps} />}
    // </button>
  );
}
